import React, { useContext } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor from 'react-scrollable-anchor';

import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';

import ContentBlock from 'components/Content/ContentBlock';
import BrandCTA from 'components/BrandCta';
import HeadlineBar from 'components/Content/HeadlineBar';
import ColorBlock from 'components/Content/ColorBlock';
import TableBlock from 'components/Content/TableBlock';
// import DownloadBlock from 'components/DownloadBlock';

// import page style
import './b12-options.scss';

const B12optionsPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const tableData = [
    [
      'FDA-approved',
      'Prescription',
      'Once-a-week dosing',
      'Odorless',
      'Tasteless',
      'Can be taken at home',
      'Not an injection',
    ],
    ['Can be taken at home', 'Not an injection'],
    ['FDA-approved', 'Prescription', 'Odorless', 'Tasteless'],
  ];

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-b12options">
        <ContentBlock id="b12options-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <HeadlineBar id="b12options-headliner" blue>
                  VITAMIN B<sub className="vitaminb12-sub">12</sub>{' '}
                  <span className="yellow">OPTIONS</span>
                </HeadlineBar>
                <h2 className="txt-uppercase">
                  WAYS TO ADMINISTER FOR YOUR APPROPRIATE PATIENTS
                </h2>
                <p>
                  When it comes to treating vitamin B12 deficiency, there are
                  many ways for your appropriate patients to receive
                  supplementation. Here are some of the common options:
                </p>
                <ColorBlock white id="colorblock-patient-resources">
                  <Row>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#fbaf40"
                        dataList={tableData[0]}
                        tableTitle={brandNames.NASCOBAL + '<br/>Nasal Spray'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#e6f2fb"
                        dataList={tableData[1]}
                        tableTitle={'SUBLINGUAL<br/>(under-the-tongue)'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TableBlock
                        headBgColor="#f3f3f3"
                        dataList={tableData[2]}
                        tableTitle={'INJECTIONS<br/>&nbsp;'}
                      />
                    </Col>
                  </Row>
                </ColorBlock>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/about"
                  LinkCaption="WHAT IS NASCOBAL® NASAL SPRAY"
                >
                  WHAT IS NASCOBAL<sup className="brand-reg-mark">&reg;</sup>{' '}
                  NASAL SPRAY
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/how"
                  LinkCaption="DISCOVER CONVENIENT DOSING"
                >
                  DISCOVER CONVENIENT DOSING
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-b12options">
      <Seo {...metaData} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default B12optionsPage;
